"use client";
import Heart from "@/components/icons/Heart";
import useModal from "@/lib/hooks/useModal";
import useWishlist from "@/lib/hooks/useWishlist";
import clsx from "clsx";
import styles from "./wishlist.module.css";

interface IWishlistButtonProps {
  itemId: string;
  className?: string;
}

export const WishlistButton = ({ itemId, className }: IWishlistButtonProps) => {
  const { toggle, isOnWishlist } = useWishlist();
  const isActive = isOnWishlist(itemId);
  const modal = useModal("wishlist", () => {}, true);

  return (
    <button
      className={clsx(
        styles.wishlistButton,
        isActive && styles.wishlistButton__active,
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        toggle(itemId)();

        if (!isActive) {
          modal.openModal();
        }
      }}
    >
      <Heart />
    </button>
  );
};
