import { PDPProduct } from "@/app/product/[product]/page";
import { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { CartItemMaybeHooked } from "@/lib/hooks/useSelection/cart/useItems";
import { CentraVoucher } from "@frend-digital/centra/schemas/selection/utilitySchemas";
import { md5, utf8_encode } from "./hash";

declare global {
  interface Window {
    dataLayer: any;
    ADT: any;
  }
}

export const pageview = (url: string) => {
  window?.dataLayer?.push({
    config: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`,
    page_path: url,
  });
};

interface GTagEvent {
  action: string;
  category: string;
  label: string;
  value: number;
}

export const event = ({ action, category, label, value }: GTagEvent) => {
  window?.dataLayer?.push({
    event: action,
    event_category: category,
    event_label: label,
    revenue: value,
  });
};

export const generateGTagItem = (
  item: PDPProduct,
  price?: number,
  quantity: number = 1,
) => {
  const item_id = item?.sku || "";
  const item_name = item?.name || "";
  const item_category = item?.breadcrumbs?.[0]?.name || "";
  const item_variant = item?.variantName || "";
  const item_price = price || NaN;
  const item_quantity = quantity;

  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price: item_price,
    quantity: item_quantity,
  };
};

export const convertPCToGtagItem = (
  item: ProductCard,
  price?: number,
  quantity: number = 1,
) => {
  const item_id = item.sku || "";
  const item_name = item?.core.name || "";
  const item_category = item?.breadcrumbs?.[0]?.name || "";
  const item_variant = item?.core.variantName || "";
  const item_price = price || NaN;
  const item_quantity = quantity;

  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price: item_price,
    quantity: item_quantity,
  };
};

export const convertCartToGTagItem = (item: CartItemMaybeHooked) => {
  const item_id = item?.sku || "";
  const item_name = item?.name || "";
  const item_category = item?.category || "";
  const item_variant = item?.variant || "";
  const item_price = item.priceAsNumber || NaN;
  const item_quantity = item.quantity;

  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price: item_price,
    quantity: item_quantity,
  };
};

export interface GTagItem {
  item_id: string; // SKU
  item_name: string; // Product Name
  discount?: number;
  item_category: string; // Top Category /women
  item_category2?: string; // Sub Category /women/!dresses!
  item_category3?: string;
  item_variant: string; // Variant name
  price: number; // Price
  quantity?: number; // How many
}
interface EventCartProps {
  currency: string;
  value?: number;
  items: GTagItem[] | [];
}

export const eventAddToCart = ({ currency, value, items }: EventCartProps) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "add_to_cart",
    ecommerce: {
      currencyCode: currency,
      revenue: value,
      products: items,
    },
  });
};

export const eventProductDetail = ({
  currency,
  items,
  value,
}: EventCartProps) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "product_detail",
    ecommerce: {
      currency,
      value,
      items,
    },
  });
};

export const eventViewCart = ({ currency, value, items }: EventCartProps) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "view_cart",
    ecommerce: {
      currencyCode: currency,
      revenue: value,
      products: items,
    },
  });
};

export const eventBeginCheckout = ({
  currency,
  value,
  items,
}: EventCartProps) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "begin_checkout",
    ecommerce: {
      currencyCode: currency,
      revenue: value,
      products: items,
    },
  });
};

interface EventPurchaseProps {
  transaction_id: string;
  tax: number;
  shipping: number;
  coupon: string | CentraVoucher;
  currency: string;
  value: number;
  items: GTagItem[];
  email?: string;
}

export const eventPurchase = ({
  transaction_id,
  tax,
  shipping,
  coupon,
  currency,
  value,
  items,
  email,
}: EventPurchaseProps) => {
  try {
    if (window?.ADT?.Tag) {
      /* AdTraction */
      window.ADT.Tag.t = 3;
      window.ADT.Tag.c = currency;
      window.ADT.Tag.tp = 1909755022;
      window.ADT.Tag.am = value - shipping - tax;
      window.ADT.Tag.ti = transaction_id;
      if (email) {
        window.ADT.Tag.xd = md5(utf8_encode(email));
      }
      window.ADT.Tag.cpn =
        typeof coupon === "string" ? coupon : coupon?.voucher || "";
      window.ADT?.Tag?.doEvent();
    }

    /* GTM */
    window?.dataLayer?.push({
      event: "purchase",
      ecommerce: {
        affiliation: "Online Store",
        transaction_id,
        tax,
        shipping,
        coupon: typeof coupon === "string" ? coupon : coupon?.voucher || "",
        currency: currency,
        value: value,
        items,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
