"use client";
import type { getProductSizes } from "@frend-digital/centra/src/formatting/atomics/baseAtomics";
import isEqual from "lodash.isequal";
import { useState } from "react";

export type ProductSizes = ReturnType<typeof getProductSizes>;

type ProductSize = {
  fewLeft: boolean;
  name: string;
  label: string;
  stock: boolean;
  itemId: string;
};
const useSizes = (sizes?: ProductSize[]) => {
  const [selected, setSelected] = useState<ProductSize | null>(null);
  //
  const formattedSizes = sizes?.map((size: ProductSize) => {
    return {
      ...size,
      isSelected: isEqual(selected?.itemId, size?.itemId),
      selectSize: () => setSelected(size),
    };
  });

  return {
    sizes: formattedSizes,
    selectedSize:
      selected !== null
        ? selected
        : sizes?.length === 1
        ? sizes?.[0]
        : undefined,
  };
};

export default useSizes;
