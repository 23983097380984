const Heart = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8168 10.1835C11.4369 11.5838 9.85431 13.1869 8.75378 14.2914L1.61689 7.1069C1.07317 6.50617 0.104811 5.138 0.675215 3.54087C1.44007 1.39927 3.1983 0.546668 4.63299 0.502225C6.6212 0.440637 7.52823 1.67184 8.2292 2.62334L8.24605 2.64621L8.68257 3.23863L9.07258 2.61461C9.59212 1.78334 10.751 0.502465 12.7061 0.502465C14.3401 0.502465 16.0162 1.54244 16.7892 3.55219C17.0147 4.13853 16.9796 4.8294 16.7578 5.50996C16.5363 6.18964 16.1429 6.81176 15.7228 7.23193C15.6851 7.26955 15.4175 7.54151 14.9999 7.96595C14.4634 8.5113 13.6791 9.30836 12.8168 10.1835Z"
        fill={"var(--heart-fill)"}
        stroke={"var(--svg-color, transparent)"}
      />
    </svg>
  );
};

export default Heart;
